import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ChronoUnit, Instant, LocalDateTime } from "@js-joda/core";
import { useApplicationStore } from "web-apps/company/stores/applicationStore/applicationStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import {
  CompanyGigDto,
  GigCosts,
  GigRating,
  GigStatus,
  GigType,
} from "model/Gig";
import { ApplicationResolution, ApplicationStatus } from "model/Application";
import { ShiftDto } from "model/Shift";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { addLeadingZero, calculateBreakTime, getWorkHours } from "utils/utils";
import { getCosts, getWorkerImages } from "web-apps/company/utils/utils";
import { BannerComponent } from "components/banners/banner.component";
import { CustomIcon } from "components/icon/customIcon.component";
import { SmallImage } from "../gig/gigs.styled";
import { H3, Text } from "components/Typography/text.styled";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { LabelStyled } from "components/form/inputs/inputs.styled";
import {
  Grid,
  RowWrapper,
  ShiftLi,
  BottomText,
  Cell,
  WorkerWrapper,
  LabelWrapper,
  ImageContainer,
  EmployerCell,
  ShiftUl,
  DescriptionCell,
  LabelWrapperDescription,
  ImageWrapper,
  ButtonsContainer,
  SkillsWrapper,
  SkillTag,
  ShiftCell,
  SkillsCell,
  RateCell,
  RatingRow,
} from "./gigDetail.styled";
import { Modal } from "@material-ui/core";
import { OfferModal } from "./offerModal.component";
import { CloseGigModal } from "../gig/closeGigModal.component";
import { Api } from "services/api/api.service";
import { ModalBody } from "../calendar/weeklyCalendar.styled";
import { SaveGigForm } from "../saveGig/saveGigForm/saveGigForm.component";
import { TimeReportStatus } from "model/TimeReport";
import { useAlertStore } from "stores/alertStore/alertStore";
import moment from "moment";
import axios from "axios";

type Props = {
  gigData: CompanyGigDto;
  onGigClosed?: (gig: CompanyGigDto) => void;
};

const emptyCosts = {
  grossSalary: 0,
  obAmount: 0,
  socialFee: 0,
  simflexityFee: 0,
  totalCost: 0,
};

export const GigDetail: React.FC<Props> = ({ gigData, onGigClosed }) => {
  const { t } = useTranslation();
  const [workerImages, setWorkerImages] = useState<string[]>([]);
  const [, , alertDispatch] = useAlertStore();
  const [companyState] = useCompanyStore();
  const [applicationState] = useApplicationStore();
  const history = useHistory();
  const startTimeGig = gigData.shifts[0]
    ? LocalDateTime.ofInstant(Instant.parse(gigData.shifts[0].startTime))
    : null;
  const now = LocalDateTime.now();
  const [disableEdit, setDisableEdit] = useState(false);
  const [offerModalOpen, setOfferModalOpen] = useState(false);
  const [offerHourlyRate, setOfferHourlyRate] = useState(gigData.maxRate);
  const [offerList, setOfferList] = useState<
    {
      workerId: number;
      rate: number;
      usingOfferRate: boolean;
      type: "favorite" | "workpool";
    }[]
  >([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const maxImages = 5;
  const [ratings, setRatings] = useState<GigRating[]>([]);
  const [useOfferRateForAll, setUseOfferRateForAll] = useState(false);
  const [showCreateGigModal, setShowCreateGigModal] = useState(false);
  const [cloneGig, setCloneGig] = useState(false);
  const [gigMinCost, setGigMinCost] = useState<GigCosts>(emptyCosts);
  const [gigMaxCost, setGigMaxCost] = useState<GigCosts>(emptyCosts);

  useEffect(() => {
    if (companyState.company?.id) {
      getCosts(
        gigData.shifts,
        gigData.minRate,
        gigData.maxRate,
        gigData.type,
        companyState.company.id,
        gigData.paymentType
      ).then((res) => {
        if (res) {
          setGigMinCost(res.minCost);
          setGigMaxCost(res.maxCost);
        }
      });
    }
  }, [companyState.company?.id, gigData]);

  useEffect(() => {
    const data = applicationState.applications.find((application) => {
      return (
        application.gigId === gigData.id &&
        ((application.status === ApplicationStatus.CLOSED &&
          application.resolution === ApplicationResolution.ACCEPTED) ||
          application.status === ApplicationStatus.OFFERED)
      );
    });
    if (
      data ||
      gigData.status === GigStatus.CLOSED ||
      gigData.workers.length > 0
    ) {
      setDisableEdit(true);
    }
  }, [applicationState.applications]);

  useEffect(() => {
    if (gigData && companyState.company?.id) {
      Api()
        .company.gig.getGigRating(companyState.company?.id, gigData.id)
        .then((res) => {
          setRatings(res.data);
        })
        .catch((err) => {
          console.log("ERROR fetching rating for gig", err);
        });
    }
  }, [gigData]);

  const renderShift = (shift: ShiftDto, index: number) => {
    const startTime = LocalDateTime.ofInstant(Instant.parse(shift.startTime));
    const endTime = LocalDateTime.ofInstant(Instant.parse(shift.endTime));

    const shiftMinutes = startTime.until(endTime, ChronoUnit.MINUTES);
    const shiftHours = Math.floor(shiftMinutes / 60);

    const shiftTime = `${shiftHours ? shiftHours + "h" : ""} ${
      shiftMinutes % 60 ? (shiftMinutes % 60) + "min" : ""
    }`;

    const breakTimeStart =
      shift.breakStartTime &&
      LocalDateTime.ofInstant(
        Instant.parse(shift.breakStartTime) //change to start break
      );
    const breakTimeEnd =
      shift.breakEndTime &&
      LocalDateTime.ofInstant(Instant.parse(shift.breakEndTime));

    const breakTimeStartString =
      breakTimeStart &&
      breakTimeStart.toLocalTime().truncatedTo(ChronoUnit.MINUTES).toString();

    const breakTimeEndString =
      breakTimeEnd &&
      breakTimeEnd.toLocalTime().truncatedTo(ChronoUnit.MINUTES).toString();

    return (
      <ShiftLi key={index}>
        <RowWrapper justify="space-between">
          <Text
            fontSize={FontSize.H4}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {t(`WeekDays.${startTime.dayOfWeek().name()}SHORT`)}{" "}
            {startTime.dayOfMonth()}/{startTime.monthValue()}
          </Text>
          <Text
            fontSize={FontSize.H4}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {`${addLeadingZero(startTime.hour())}:${addLeadingZero(
              startTime.minute()
            )} - ${addLeadingZero(endTime.hour())}:${addLeadingZero(
              endTime.minute()
            )}`}
          </Text>
        </RowWrapper>
        <RowWrapper justify="space-between">
          <BottomText color={Color.SeaBlue600}>
            {breakTimeStartString && breakTimeEndString
              ? `${calculateBreakTime(
                  breakTimeStartString,
                  breakTimeEndString,
                  moment(
                    LocalDateTime.ofInstant(
                      Instant.parse(shift.startTime)
                    ).toString()
                  ),
                  moment(
                    LocalDateTime.ofInstant(
                      Instant.parse(shift.endTime)
                    ).toString()
                  )
                )} ${t("GigsWorker.MinutesUnpaidBreak")}`
              : "0" + t("GigsWorker.MinutesUnpaidBreak")}
          </BottomText>
          <BottomText color={Color.SeaBlue600}>{shiftTime}</BottomText>
        </RowWrapper>
      </ShiftLi>
    );
  };

  useEffect(() => {
    const workerIds = gigData.workers.map((worker) => worker.firebaseId);
    const workerImageUrls = Array(gigData.amountOfWorkers).fill(null);

    getWorkerImages(workerIds).then((data) => {
      workerImageUrls.splice(0, data.length, ...data);
      setWorkerImages(workerImageUrls);
    });
  }, [gigData.workers]);

  return (
    <>
      {startTimeGig && now > startTimeGig && (
        <BannerComponent
          backgroundColor={Color.ChampagnePink}
          fill={Color.Destructive}
        >
          <Text
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
            color={Color.Destructive}
          >
            {t("GigsCompany.GigHasPassed")}
          </Text>
          <Text color={Color.MidnightBlue}>
            {t("GigsCompany.TheStartTimeForThisGig")}
          </Text>
        </BannerComponent>
      )}
      <Grid>
        <Cell>
          <WorkerWrapper>
            <LabelWrapper>
              <LabelStyled>{t("General.Giggers")}</LabelStyled>
              <LabelStyled>{`${gigData.workers.length}/${gigData.amountOfWorkers}`}</LabelStyled>
            </LabelWrapper>
            <ImageContainer>
              {workerImages.map((url, index) => {
                if (index < maxImages) {
                  return (
                    <ImageWrapper key={index}>
                      {url ? (
                        <SmallImage src={url} />
                      ) : (
                        <CustomIcon
                          name="person"
                          size="100%"
                          color={Color.SeaBlue500}
                          padding="4px"
                        />
                      )}
                    </ImageWrapper>
                  );
                }
              })}
            </ImageContainer>
            <ButtonsContainer>
              <ButtonStyled
                style={{ margin: "5px" }}
                onClick={() => {
                  history.push(
                    `/company/applications/${gigData.id}?status=Pending`
                  );
                }}
              >
                {t("GigsCompany.Applicants")}
              </ButtonStyled>

              <div style={{ position: "relative" }}>
                <CustomIcon
                  name="clock"
                  size="40px"
                  backgroundColor={Color.BurntSienna}
                  color={Color.White}
                  padding="10px"
                  onClick={() => {
                    history.push(
                      `/company/time-reports/${gigData.id}?gig=${gigData.role}`
                    );
                  }}
                />
              </div>
              {gigData.type !== GigType.STAFFING && (
                <ButtonStyled
                  style={{ margin: "5px" }}
                  onClick={() => {
                    setOfferHourlyRate(gigData.maxRate);
                    setOfferModalOpen(true);
                  }}
                >
                  {t("GigsCompany.OfferGig")}
                </ButtonStyled>
              )}
            </ButtonsContainer>
          </WorkerWrapper>
        </Cell>
        <EmployerCell>
          <LabelStyled color={Color.MidnightBlue}>
            {t("FindWorker.Employer")}
          </LabelStyled>
          <RowWrapper justify="space-between">
            <Text
              fontSize={FontSize.H4}
              fontFamily={FontFamily.MontserratSemiBold}
            >
              {companyState.company?.companyName}
            </Text>
            <CustomIcon
              name="pin"
              size="48px"
              color={Color.MidnightBlue}
              padding="10px"
            />
          </RowWrapper>
          <RowWrapper justify="space-between">
            <BottomText>{companyState.company?.address}</BottomText>
          </RowWrapper>
        </EmployerCell>
        <Cell>
          <LabelStyled>{t("GigsCompany.RoleWanted")}</LabelStyled>
          <RowWrapper>
            <CustomIcon
              name="person"
              size="48px"
              color={Color.BurntSienna}
              padding="10px"
            />
            <Text fontSize={FontSize.Large}>
              {gigData.role && t(gigData.role, { ns: "roles" })}
            </Text>
          </RowWrapper>
        </Cell>
        <SkillsCell>
          <LabelStyled>{t("GigsCompany.SkillsWanted")}</LabelStyled>
          <SkillsWrapper>
            {gigData.skills.map((skill, index) => (
              <SkillTag key={index}>
                <Text
                  key={index}
                  color={Color.White}
                  fontFamily={FontFamily.MontserratSemiBold}
                  fontSize={FontSize.Standard}
                  style={{ margin: 0 }}
                >
                  {t(skill.skill, { ns: "skills" })}
                </Text>
              </SkillTag>
            ))}
          </SkillsWrapper>
        </SkillsCell>
        <RateCell>
          <LabelStyled>{t("GigsCompany.HourlyRate")}</LabelStyled>
          <RowWrapper>
            <CustomIcon
              name="cashier"
              size="48px"
              color={Color.BurntSienna}
              padding="10px"
            />
            <Text fontSize={FontSize.Large}>
              {t("FindWorker.HourlyRateSpanWithCurrency", {
                minRate: gigData.minRate,
                maxRate: gigData.maxRate,
              })}
            </Text>
          </RowWrapper>
          {gigMinCost && gigMaxCost && (
            <>
              <RowWrapper>
                <Text fontSize={FontSize.Small} style={{ marginRight: "10px" }}>
                  {gigMinCost && gigMinCost?.obAmount > 0
                    ? t("FindWorker.TotalSalaryWithOb") + ":"
                    : t("FindWorker.TotalSalary") + ":"}
                </Text>
                <Text fontSize={FontSize.Small}>
                  {`${(gigMinCost?.grossSalary + gigMinCost.obAmount).toFixed(
                    2
                  )} - ${(
                    gigMaxCost?.grossSalary + gigMaxCost.obAmount
                  ).toFixed(2)} ${t("ProfileCompany.Kr")}`}
                </Text>
              </RowWrapper>
              <RowWrapper>
                <Text fontSize={FontSize.Small} style={{ marginRight: "10px" }}>
                  {t("FindWorker.TotalCost")}
                </Text>
                <Text fontSize={FontSize.Small}>
                  {`${gigMinCost?.totalCost.toFixed(
                    2
                  )} - ${gigMaxCost?.totalCost.toFixed(2)} ${t(
                    "ProfileCompany.Kr"
                  )}`}
                </Text>
              </RowWrapper>
            </>
          )}
        </RateCell>
        <Cell style={{ width: "100%", maxHeight: "90px" }}>
          <LabelStyled>{t("GigsCompany.TypeOfGig")}</LabelStyled>
          <RowWrapper>
            <Text
              fontSize={FontSize.Standard}
              color={Color.White}
              fontFamily={FontFamily.MontserratBold}
              style={{ margin: "12px" }}
              backgroundColor={Color.SeaBlue600}
              padding={true}
            >
              {t(`GigsCompany.${gigData.type}`)}
            </Text>
            <Text
              fontSize={FontSize.Standard}
              color={Color.MidnightBlue}
              fontFamily={FontFamily.MontserratBold}
              style={{ margin: "12px" }}
              backgroundColor={Color.Positive}
              padding={true}
            >
              {gigData.paymentType == 'None' ? t('GigsCompany.NoPaymentType') : t('GigsCompany.SimflexityPay') }
            </Text>
          </RowWrapper>
        </Cell>
        <ShiftCell>
          <LabelWrapper>
            <LabelStyled>
              {t("FindWorker.Shifts")} ({gigData.shifts.length})
            </LabelStyled>
            <LabelStyled>{getWorkHours(t, ...gigData.shifts)}</LabelStyled>
          </LabelWrapper>
          <ShiftUl>
            {gigData.shifts.map((shift, index) => {
              return renderShift(shift, index);
            })}
          </ShiftUl>
        </ShiftCell>
        <DescriptionCell>
          <LabelWrapperDescription>
            <LabelStyled>{t("FindWorker.Description")}</LabelStyled>
          </LabelWrapperDescription>
          {gigData.description ? (
            <Text style={{ whiteSpace: "pre-line" }}>
              {gigData.description}
            </Text>
          ) : null}
        </DescriptionCell>
      </Grid>
      <Cell style={{ marginBottom: "16px" }}>
        <DescriptionCell>
          <LabelStyled>{t("GigsCompany.GigRatings")}</LabelStyled>
          {ratings.map((rating) => (
            <>
              <RatingRow>
                <Text
                  fontSize={FontSize.Large}
                  fontFamily={FontFamily.MontserratSemiBold}
                >
                  {rating.firstName} {rating.lastName} {rating.rating}/5
                </Text>
                <CustomIcon
                  name="star-filled"
                  size="20px"
                  color={Color.BurntSienna}
                  padding="2px"
                />
              </RatingRow>
              {rating.message && (
                <Text style={{ marginTop: "8px" }}>"{rating.message}"</Text>
              )}
            </>
          ))}
          {ratings.length === 0 && (
            <Text color={Color.SeaBlue600}>
              {t("GigsCompany.ThereAreNoRatings")}
            </Text>
          )}
        </DescriptionCell>
      </Cell>

      <RowWrapper paddingTop={10}>
        <ButtonStyled
          onClick={() => {
            setShowCreateGigModal(true);
          }}
          icon={true}
        >
          {t("GigsCompany.EditGig")}
          <CustomIcon
            name="pen"
            size="25px"
            color={
              (startTimeGig && startTimeGig < now) || disableEdit
                ? Color.SeaBlue500
                : Color.White
            }
            padding="2px"
          />
        </ButtonStyled>
        <ButtonStyled
          backgroundColor={Color.MidnightBlue}
          onClick={() => {
            setCloneGig(true);
            setShowCreateGigModal(true);
          }}
          icon
        >
          {t("GigsCompany.CloneGig")}
          <CustomIcon
            name="clone"
            size="25px"
            color={Color.White}
            padding="2px"
          />
        </ButtonStyled>
        <ButtonStyled
          backgroundColor={Color.Destructive}
          onClick={() => {
            if (companyState.company?.id) {
              Api()
                .company.timeReport.getGigTimeReports(
                  companyState.company?.id,
                  gigData.id
                )
                .then((res) => {
                  if (
                    res.data.find(
                      (timeReport) => timeReport.status !== TimeReportStatus.NEW
                    )
                  ) {
                    alertDispatch({
                      type: "SHOW_ALERT",
                      payload: {
                        icon: "alert",
                        title: "",
                        message: t(
                          "GigsCompany.ThisGigHasConnectedTimeReports"
                        ),
                      },
                    });
                  } else {
                    setDeleteModalOpen(true);
                  }
                });
            }
          }}
          disabled={gigData.status === GigStatus.CLOSED}
          icon
        >
          {t("GigsCompany.CloseGig")}
          <CustomIcon
            name="trashcan"
            size="25px"
            color={
              gigData.status === GigStatus.CLOSED
                ? Color.SeaBlue500
                : Color.White
            }
            padding="2px"
          />
        </ButtonStyled>
      </RowWrapper>
      <Modal
        open={offerModalOpen}
        onClose={() => {
          setOfferModalOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <OfferModal
          offerList={offerList}
          setOfferList={setOfferList}
          createGig={false}
          gigData={gigData}
          setOfferModalOpen={setOfferModalOpen}
          offerRate={offerHourlyRate}
          setOfferRate={setOfferHourlyRate}
          useOfferRateForAll={useOfferRateForAll}
          setUseOfferRateForAll={setUseOfferRateForAll}
        />
      </Modal>
      <CloseGigModal
        modalOpen={deleteModalOpen}
        setModalOpen={setDeleteModalOpen}
        gigData={gigData}
        onGigClosed={onGigClosed}
      />
      <Modal
        open={showCreateGigModal}
        onClose={() => {
          setShowCreateGigModal(false);
        }}
      >
        <ModalBody>
          <H3>
            {cloneGig ? t("GigsCompany.CloneGig") : t("GigsCompany.EditGig")}
          </H3>
          <SaveGigForm
            setShowCreateGigModal={setShowCreateGigModal}
            gigData={gigData}
            isClone={cloneGig}
          />
        </ModalBody>
      </Modal>
    </>
  );
};
