import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Color } from "config/colors";
import { CustomIcon } from "components/icon/customIcon.component";
import { CustomLink, Text } from "components/Typography/text.styled";
import { FontFamily, FontSize } from "config/font";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useApplicationStore } from "web-apps/company/stores/applicationStore/applicationStore";
import {
  ApplicationDto,
  ApplicationResolution,
  ApplicationStatus,
} from "model/Application";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";
import { useAlertStore } from "stores/alertStore/alertStore";
import { ApplicantDetailsModal } from "../../applicantDetails/applicantDetailsModal.component";
import {
  DashBoardCell,
  TitleContainer,
  DashboardCellContent,
  TimeReportCell,
  CellContent,
  VerticalDivider,
  GiggerContainer,
  ApplicationRoleContainer,
  IconsContainer,
  IconCircularContainer,
  Badge,
  TimeAndDateContainer,
} from "../dashboard.styled";

export const DashboardApplications: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [companyState] = useCompanyStore();
  const [applicationState, applicationActions, applicationDispatch] =
    useApplicationStore();
  const [, , alertDispatch] = useAlertStore();
  const [unhandledApplications, setUnhandledApplications] = useState<
    ApplicationDto[]
  >([]);
  const [showApplicationDetails, setShowApplicationDetails] = useState(false);
  const [currentApplication, setCurrentApplication] =
    useState<ApplicationDto>();

  const showAlert = (
    type: "Offered" | "Rejected" | "Update",
    error?: boolean
  ) => {
    const alertConfig = {
      icon: error ? "alert" : "checkmark",
      title: error
        ? t("Alerts.OhNo")
        : type === "Offered"
        ? t("ApplicationsCompany.Offered")
        : t("ApplicationsCompany.Rejected"),
      message: error
        ? t("ApplicationsCompany.ApplicationCouldNotBeUpdated")
        : type === "Offered"
        ? t("ApplicationsCompany.ApplicantHasBeenOfferedGig")
        : t("ApplicationsCompany.ApplicationHasBeenRejected"),
    };

    alertDispatch({
      type: "SHOW_ALERT",
      payload: alertConfig,
    });
  };

  useEffect(() => {
    if (companyState.company?.id) {
      applicationDispatch({ type: "SET_IS_LOADING", payload: true });
      Api()
        .company.application.getPaginatedApplicationsPending(
          companyState.company.id,
          0,
          5
        )
        .then((res) => {
          setUnhandledApplications(res.data);
          applicationDispatch({ type: "SET_IS_LOADING", payload: false });
        })
        .catch((err) => {
          console.log(err);
          applicationDispatch({ type: "SET_IS_LOADING", payload: false });
        });
    }
  }, [companyState.company?.id]);

  const updateApplicationStatus = (
    status: ApplicationStatus.OFFERED | ApplicationStatus.CLOSED,
    applicant: ApplicationDto
  ) => {
    if (companyState.company) {
      const data = {
        ...applicant,
        status,
        ...(status === ApplicationStatus.CLOSED && {
          resolution: ApplicationResolution.REJECTED,
        }),
      };
      applicationActions
        .updateApplicationStatus(companyState.company.id, applicant.id, data)
        .then(() => {
          applicationDispatch({
            type: "GET_PENDING_APPLICATIONS_COUNT",
            payload: applicationState.applicationsCount - 1,
          });
          if (status === ApplicationStatus.OFFERED) {
            showAlert("Offered");
          } else {
            showAlert("Rejected");
          }
          setUnhandledApplications(
            unhandledApplications.filter(
              (application) => application.id !== data.id
            )
          );
        })
        .catch(() => {
          showAlert("Update", true);
        });
    }
  };

  return (
    <>
      <DashBoardCell>
        <TitleContainer>
          <CustomLink
            color={Color.MidnightBlue}
            onClick={() => history.push("./applications")}
            fontSize={FontSize.H3}
            fontFamily={FontFamily.MontserratRegular}
          >
            {t("ApplicationsCompany.Applications")}
          </CustomLink>
          {unhandledApplications && unhandledApplications.length > 4 && (
            <Badge>
              <CustomLink
                onClick={() => history.push("./applications")}
                color={Color.White}
                fontFamily={FontFamily.MontserratSemiBold}
                fontSize={FontSize.Small}
              >
                +{unhandledApplications.length - 4}
              </CustomLink>
            </Badge>
          )}
        </TitleContainer>
        <DashboardCellContent>
          {!applicationState.isLoading &&
            unhandledApplications.map((app, index) => {
              if (index < 4) {
                return (
                  <TimeReportCell isApplication>
                    <CellContent
                      onClick={() => {
                        setCurrentApplication(app);
                        setShowApplicationDetails(true);
                      }}
                    >
                      <TimeAndDateContainer>
                        <Text
                          color={Color.MidnightBlue}
                          fontFamily={FontFamily.MontserratSemiBold}
                          fontSize={FontSize.H4}
                          style={{ margin: 0, padding: 0 }}
                        >
                          {`${moment(app.gig.startTime).format("DD/MM")}`}
                        </Text>
                        <Text color={Color.SeaBlue600}>
                          {`${moment(app.gig.startTime).format(
                            "HH:mm"
                          )} - ${moment(app.gig.endTime).format(" HH:mm")}`}
                        </Text>
                      </TimeAndDateContainer>
                      <VerticalDivider />
                      <GiggerContainer>
                        <Text
                          style={{ margin: 0, padding: 0 }}
                          fontSize={FontSize.Small}
                          color={Color.SeaBlue600}
                        >
                          {t("General.Giggers")}
                        </Text>
                        <Text
                          color={Color.MidnightBlue}
                          fontFamily={FontFamily.MontserratRegular}
                        >{`${app.worker.firstName} ${app.worker.lastName}`}</Text>
                      </GiggerContainer>
                      <ApplicationRoleContainer>
                        <Text
                          style={{ margin: 0, padding: 0 }}
                          fontSize={FontSize.Small}
                          color={Color.SeaBlue600}
                        >{`${t("General.Role")}`}</Text>
                        <Text>
                          {`${app.gig.role}
                      ${
                        moment(app.gig.endTime).format("DD/MM/YY") !==
                        moment(app.gig.startTime).format("DD/MM/YY")
                          ? moment(app.gig.endTime).format("DD/MM")
                          : ""
                      }`}
                        </Text>
                      </ApplicationRoleContainer>
                      </CellContent>
                      <IconsContainer>
                        <IconCircularContainer isAccept>
                          <CustomIcon
                            style={{ zIndex: 1000 }}
                            onClick={() =>
                              updateApplicationStatus(
                                ApplicationStatus.OFFERED,
                                app
                              )
                            }
                            name="checkmark"
                            size="24px"
                            padding="2px"
                            color={Color.White}
                          />
                        </IconCircularContainer>
                        <IconCircularContainer>
                          <CustomIcon
                            style={{ zIndex: 1000 }}
                            onClick={() =>
                              updateApplicationStatus(
                                ApplicationStatus.CLOSED,
                                app
                              )
                            }
                            name="cross"
                            size="24px"
                            padding="2px"
                            color={Color.White}
                          />
                        </IconCircularContainer>
                      </IconsContainer>
                  </TimeReportCell>
                );
              }
            })}
          {!applicationState.isLoading &&
            unhandledApplications.length === 0 && (
              <Text color={Color.SeaBlue400}>
                {t("ApplicationsCompany.NoNewApplications")}
              </Text>
            )}
          {applicationState.isLoading && <CircularProgress />}
        </DashboardCellContent>
      </DashBoardCell>

      {/* Modals */}
      {showApplicationDetails && currentApplication && (
        <ApplicantDetailsModal
          applicant={currentApplication}
          setShowApplicationModal={setShowApplicationDetails}
          showApplicationModal={showApplicationDetails}
          applications={unhandledApplications}
          setApplications={setUnhandledApplications}
          unhandled={true}
        />
      )}
    </>
  );
};
