import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TimeReportDto,
  TimeReportResolution,
  TimeReportStatus,
  WorkerWithTimeReports,
} from "model/TimeReport";
import { PaymentMethod } from "model/Gig";
import { WorkerTimeReports } from "./workerTimeReports.component";
import { TimeReportModal } from "./timeReportModal.component";
import {
  WorkerTimeReportContainer,
  NoTimeReportsWrapper,
  CircularProgressWrapper,
} from "./monthlyOverview.styled";
import { Text, H4 } from "components/Typography/text.styled";
import { RatingModal } from "./ratingModal.component";
import { EndorseModal } from "./endorsements/endorseModal.component";
import { SelectRow, SelectText } from "./timeReports.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import { Color } from "config/colors";
import { ButtonStyled } from "components/buttons/buttons.styled";
import {
  ButtonContainer,
} from "../applicantDetails/applicantDetails.styled";
import { useTimeReportStore } from "web-apps/company/stores/timeReportStore/timeReportStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { useAlertStore } from "stores/alertStore/alertStore";
import { CircularProgress, Modal } from "@material-ui/core";
import { FontFamily, FontSize } from "config/font";
import { Api } from "services/api/api.service";
import { isWithinXMinutes, updateTimeReportInContext } from "utils/utils";
import { Banner, InfoCircleBanner } from "components/banners/banner.styled";
import { Link } from "react-router-dom";
import MultipleSelectionPopupModal, { CommonMultipleSelectionModalProps } from "./multipleSelectionPopupModal.component";

export const UnhandledOverview: React.FC = () => {
  const { t } = useTranslation();
  const [chosenTimeReportWithWorker, setChosenTimeReportWithWorker] =
    useState<TimeReportDto | null>(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [ratingModalOpen, setRatingModalOpen] = useState(false);
  const [endorseModalOpen, setEndorseModalOpen] = useState(false);

  const [triggerFetchTimeReports, setTriggerFetchTimeReports] =  useState(0)

  const incrementTriggerFetchTimeReports = () => {
    setTriggerFetchTimeReports(prevValue => prevValue + 1);
  };

  const [select, setSelect] = useState(false);
  const [selectedTimeReports, setSelectedTimeReports] = useState([]);
  const [timeReportState, timeReportActions, timeReportDispatch] =
    useTimeReportStore();
  const [companyState] = useCompanyStore();
  const [, , alertDispatch] = useAlertStore();
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [unhandledTimeReports, setUnhandledTimeReports] = useState<
    WorkerWithTimeReports[]
  >([]);
  const [showCheckInCheckOutColumns, setShowCheckInCheckOutColumns] =
    useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const getTimeReports = () => {
    setIsLoading(true);
    const companyId = companyState.company?.id;

    if (companyId) {
      Api()
        .company.timeReport.getUnhandledTimeReports(companyId)
        .then((res) => {
          setIsLoading(false);
          timeReportDispatch({
            type: "UPDATE_UNHANDLED_TIME_REPORTS",
            payload: res.data,
          });
          setUnhandledTimeReports(res.data);
          timeReportActions.getUnhandledTimeReportCount(companyId);
        })
        .catch((err) => {
          console.log("Error fetching unhandled timereports", err);
          let errorMessage: string;

          if (err instanceof Error) {
            errorMessage = err.message;
          } else {
            errorMessage = String(err);
          }
          setUnhandledTimeReports([]);
          setErrorMessage(errorMessage);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    getTimeReports();
  }, [companyState.company?.id, triggerFetchTimeReports]);

  const thereAreNoTimeReportsWithDifferenceInTime = () => {
    return unhandledTimeReports.map((timeReportData) => {
      return timeReportData.timeReports.some((timeReport: any) => {
        return (
          (timeReport.checkInTime !== null &&
            isWithinXMinutes(
              new Date(timeReport.startTime),
              new Date(timeReport.checkInTime),
              15
            )) ||
          (timeReport.checkOutTime !== null &&
            isWithinXMinutes(
              new Date(timeReport.endTime),
              new Date(timeReport.checkOutTime),
              15
            ))
        );
      });
    });
  };

  const handleSelectedTimeReports = async (
    response: TimeReportStatus.APPROVED | TimeReportStatus.REJECTED
  ) => {
    setIsLoading(true);
    let errors = 0;

    await Promise.all(
      selectedTimeReports.map(async (selectedTimeReport: TimeReportDto) => {
        let newStatus: TimeReportStatus;
        if (response === TimeReportStatus.APPROVED) {
          newStatus =
            selectedTimeReport.paymentType === PaymentMethod.SIMFLEXITY_PAY
              ? TimeReportStatus.APPROVED
              : TimeReportStatus.CLOSED;
        } else {
          newStatus = TimeReportStatus.REJECTED;
        }
        const newTimeReport = {
          ...selectedTimeReport,
          status: newStatus,
          ...(newStatus === TimeReportStatus.CLOSED && {
            resolution: TimeReportResolution.TIME_REPORTED,
          }),
        };
        await Api()
          .company.timeReport.updateGigTimeReport(
            selectedTimeReport.companyId,
            selectedTimeReport.gigId,
            newTimeReport
          )
          .then(async (res) => {
            if (
              newStatus === TimeReportStatus.APPROVED &&
              selectedTimeReport.paymentType === PaymentMethod.SIMFLEXITY_PAY
            ) {
              await timeReportActions
                .createPayoutForTimeReport(selectedTimeReport)
                .then((res) => {
                  timeReportDispatch({
                    type: "UPDATE_UNHANDLED_TIME_REPORTS",
                    payload: updateTimeReportInContext(
                      timeReportState.unhandledTimeReports,
                      "UPDATE_UNHANDLED_TIME_REPORTS",
                      newTimeReport
                    ),
                  });
                })
                .catch((err) => {
                  console.log(
                    "ERROR CREATING PAYOUT ID",
                    selectedTimeReport.id,
                    err
                  );
                  errors++;
                });
            } else {
              timeReportDispatch({
                type: "UPDATE_UNHANDLED_TIME_REPORTS",
                payload: updateTimeReportInContext(
                  timeReportState.unhandledTimeReports,
                  "UPDATE_UNHANDLED_TIME_REPORTS",
                  newTimeReport
                ),
              });
            }
          })
          .catch((err) => {
            console.log(
              "ERROR UPDATING TIMEREPORT STATUS",
              selectedTimeReport.id,
              err
            );
            errors++;
          });
      })
    );
    if (errors === 0) {
      alertDispatch({
        type: "SHOW_ALERT",
        payload: {
          icon: "checkmark",
          title: t("Alerts.Updated"),
          message: t("CompanyTimeReport.TimeReportsHaveBeenUpdated"),
        },
      });
    } else {
      alertDispatch({
        type: "SHOW_ALERT",
        payload: {
          icon: "alert",
          title: t("Alerts.OhNo"),
          message: t("CompanyTimeReport.AllTimeReportsWereNotUpdated"),
        },
      });
    }

    if (companyState.company?.id) {
      timeReportActions.getUnhandledTimeReportCount(companyState.company.id);
    }
    setSelectedTimeReports([]);
    incrementTriggerFetchTimeReports()
    setSubmitModalOpen(false);
    setRejectModalOpen(false);
    setIsLoading(false);
  };

  const commonMultipleSelectionModalProps: CommonMultipleSelectionModalProps = {
    t,
    selectedTimeReports,
    handleSelectedTimeReports
  };

  return (
    <>
      {errorMessage != "" && (
        <Text
          fontSize={FontSize.Large}
          fontFamily={FontFamily.MontserratSemiBold}
          color={Color.Destructive}
        >
          {t("CompanyTimeReport.CouldNotFetch") + ": '" + errorMessage + "'"}
        </Text>
      )}

      {unhandledTimeReports.length !== 0 && (
        <>
          {!thereAreNoTimeReportsWithDifferenceInTime() && (
            <Banner backgroundColor={Color.Warning}>
              <InfoCircleBanner>
                <Text
                  fontFamily={FontFamily.LeagueSpartanBold}
                  color={Color.White}
                  padding
                  fontSize={FontSize.Standard}
                >
                  i
                </Text>
              </InfoCircleBanner>
              <Text
                fontSize={FontSize.Large}
                fontFamily={FontFamily.MontserratSemiBold}
                color={Color.Destructive}
              >
                {t(
                  "CompanyTimeReport.ThereIsUnhandledTimeReportsWhereTimeDiffers"
                )}
              </Text>
            </Banner>
          )}
          
          <SelectRow>
            <SelectText
              fontFamily={FontFamily.MontserratSemiBold}
              onClick={() => {
                setShowCheckInCheckOutColumns(!showCheckInCheckOutColumns);
              }}
            >
              {t("CompanyTimeReport.ShowCheckInCheckOutColumns")}
            </SelectText>
            <CustomIcon
              name="checkmark"
              size="27px"
              color={Color.White}
              backgroundColor={
                showCheckInCheckOutColumns ? Color.MidnightBlue : Color.White
              }
              padding="2px"
              onClick={() => {
                setShowCheckInCheckOutColumns(!showCheckInCheckOutColumns);
              }}
              style={{
                border: `2px solid ${Color.MidnightBlue}`,
                flexShrink: 0,
                marginLeft: "8px",
                marginRight: "20px",
              }}
            />
            <SelectText
              fontFamily={FontFamily.MontserratSemiBold}
              onClick={() => {
                setSelect(!select);
              }}
            >
              {t("CompanyTimeReport.SelectMultiple")}
            </SelectText>
            <CustomIcon
              name="checkmark"
              size="27px"
              color={Color.White}
              backgroundColor={select ? Color.MidnightBlue : Color.White}
              padding="2px"
              onClick={() => {
                setSelectedTimeReports([]);
                setSelect(!select);
              }}
              style={{
                border: `2px solid ${Color.MidnightBlue}`,
                flexShrink: 0,
                marginLeft: "8px",
              }}
            />
          </SelectRow>
        </>
      )}

      {!isLoading && unhandledTimeReports.length !== 0 ? (
        unhandledTimeReports.map((timeReportData) => {
          return (
            <WorkerTimeReportContainer key={timeReportData.id}>
              <Link to={`workers/${timeReportData.id}`}>
                <H4>
                  {`${timeReportData.firstName} ${timeReportData.lastName}`}{" "}
                  <CustomIcon
                    name="person"
                    size="20px"
                    color={Color.MidnightBlue}
                  />
                </H4>
              </Link>
              <WorkerTimeReports
                select={select}
                selectedTimeReports={selectedTimeReports}
                setSelectedTimeReports={setSelectedTimeReports}
                monthlyWorkerWithTimeReports={timeReportData}
                setChosenTimeReportWithWorker={setChosenTimeReportWithWorker}
                setModalOpen={setModalOpen}
                showCheckInCheckOutColumns={showCheckInCheckOutColumns}
                unhandledView
              />
            </WorkerTimeReportContainer>
          );
        })
      ) : isLoading ? (
        <CircularProgressWrapper>
          <CircularProgress />
        </CircularProgressWrapper>
      ) : (
        !errorMessage && (
          <NoTimeReportsWrapper>
            <H4>{t("CompanyTimeReport.NoUnhandledTimeReports")}</H4>
            <Text>{t("CompanyTimeReport.NoUnhandledTimeReportsInfo")}</Text>
          </NoTimeReportsWrapper>
        )
      )}

      {select && (
        <ButtonContainer>
          <ButtonStyled
            disabled={selectedTimeReports.length < 1}
            backgroundColor={Color.Destructive}
            onClick={() => setRejectModalOpen(true)}
          >
            {t("CompanyTimeReport.RejectSelected")}
          </ButtonStyled>
          <ButtonStyled
            disabled={selectedTimeReports.length < 1}
            onClick={() => setSubmitModalOpen(true)}
          >
            {t("CompanyTimeReport.ApproveSelected")}
          </ButtonStyled>
        </ButtonContainer>
      )}

      {chosenTimeReportWithWorker && (
        <TimeReportModal
          chosenTimeReportWithWorker={chosenTimeReportWithWorker}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setRatingModalOpen={setRatingModalOpen}
          triggerRefreshTimeReports={incrementTriggerFetchTimeReports}
        />
      )}

      {ratingModalOpen && chosenTimeReportWithWorker && (
        <RatingModal
          ratingModalOpen={ratingModalOpen}
          setRatingModalOpen={setRatingModalOpen}
          chosenTimeReportWithWorker={chosenTimeReportWithWorker}
          setEndorseModalOpen={setEndorseModalOpen}
        />
      )}

      {endorseModalOpen && chosenTimeReportWithWorker && (
        <EndorseModal
          endorseModalOpen={endorseModalOpen}
          setEndorseModalOpen={setEndorseModalOpen}
          chosenTimeReportWithWorker={chosenTimeReportWithWorker}
        />
      )}

      {submitModalOpen && (
        <MultipleSelectionPopupModal
          onClose={() => setSubmitModalOpen(false)}
          isLoading={isLoading}
          status={TimeReportStatus.APPROVED}
          modalProps={commonMultipleSelectionModalProps}
        />
      )}

      {rejectModalOpen && (
        <MultipleSelectionPopupModal
          onClose={() => setRejectModalOpen(false)}
          isLoading={isLoading}
          status={TimeReportStatus.REJECTED}
          modalProps={commonMultipleSelectionModalProps}
        />
      )}
    </>
  );
};
