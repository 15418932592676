import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getWorkHours } from "utils/utils";
import { CompanyGigDto } from "model/Gig";
import { getWorkerImages } from "web-apps/company/utils/utils";
import { getDateString } from "utils/utils";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { CustomIcon } from "components/icon/customIcon.component";
import { Text } from "components/Typography/text.styled";
import {
  WorkerCountWrapper,
  ImageWrapper,
  SmallImage,
  SimpleCell,
  SimpleDateHours,
  DividerLine1,
  SimpleTitleStatus,
  DividerLine2,
  RowWrapper,
} from "./gigs.styled";

type Props = {
  gigData: CompanyGigDto;
};

export const ArchivedGig: React.FC<Props> = ({ gigData }) => {
  const { t } = useTranslation();
  const [workerImages, setWorkerImages] = useState<string[]>([]);

  const maxImages = 5;

  useEffect(() => {
    const workerIds = gigData.workers.map((worker) => worker.firebaseId);
    const workerImageUrls = Array(gigData.amountOfWorkers).fill(null);

    getWorkerImages(workerIds).then((data) => {
      workerImageUrls.splice(0, data.length, ...data);
      setWorkerImages(workerImageUrls);
    });
  }, [gigData.workers]);

  return (
    <SimpleCell>
      <SimpleTitleStatus>
        <Link to={`../gig/${gigData.id}`}>
          <Text
            fontSize={FontSize.H4}
            fontFamily={FontFamily.MontserratSemiBold}
            style={{ margin: 0 }}
          >
            {t(gigData.role || "", { ns: "roles" })}
          </Text>
        </Link>
        <RowWrapper>
          <Text
            color={Color.White}
            backgroundColor={Color.SeaBlue400}
            fontSize={FontSize.Small}
            padding
            style={{ margin: 0, lineHeight: FontSize.Small }}
          >
            {t(`GigsCompany.${gigData.type}`)}
          </Text>
          <Text
            color={Color.White}
            backgroundColor={Color.SeaBlue600}
            fontSize={FontSize.Small}
            padding
            style={{ lineHeight: FontSize.Small }}
          >
            {t(`GigsCompany.${gigData.status}`)}
          </Text>
        </RowWrapper>
      </SimpleTitleStatus>
      <DividerLine1 />
      <SimpleDateHours>
        <Text fontSize={FontSize.Large} color={Color.MidnightBlue}>
          {gigData.shifts[0] && getDateString(gigData.shifts[0].startTime)}
          {gigData.shifts && gigData.shifts.length > 0 && getDateString(gigData.shifts[0].startTime) !==
            getDateString(gigData.shifts[gigData.shifts.length - 1].endTime) &&
            ` - ${getDateString(
              gigData.shifts[gigData.shifts.length - 1].endTime
            )}`}
        </Text>
        <Text
          fontSize={FontSize.Small}
          color={Color.White}
          backgroundColor={Color.SeaBlue600}
          padding
          style={{ margin: 0, lineHeight: FontSize.Small }}
        >
          {getWorkHours(t, ...gigData.shifts)}
        </Text>
      </SimpleDateHours>
      <DividerLine2 />
      <WorkerCountWrapper>
        {workerImages.map((url, index) => {
          if (index < maxImages) {
            return (
              <ImageWrapper key={index}>
                {url ? (
                  <SmallImage src={url} />
                ) : (
                  <CustomIcon
                    name="person"
                    size="100%"
                    color={Color.SeaBlue500}
                    padding="4px"
                  />
                )}
              </ImageWrapper>
            );
          }
        })}

        <Text color={Color.SeaBlue400} style={{ margin: 0 }}>
          {gigData.workers?.length}/{gigData.amountOfWorkers}
        </Text>
      </WorkerCountWrapper>
    </SimpleCell>
  );
};
