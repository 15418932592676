import styled from "styled-components";
import { Text } from "components/Typography/text.styled";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: repeat(3, auto);
  grid-gap: 10px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    display: unset;
  }
`;

type RowProps = {
  justify?: string;
  paddingTop?: number;
};

type OfferCellProps = {
  disabled?: boolean;
};

export const RowWrapper = styled.div<RowProps>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: ${(props) => props.paddingTop && props.paddingTop}px;
  justify-content: ${(props) => props.justify && props.justify};
`;

export const ShiftUl = styled.ul`
  margin: 0px;
  padding-left: 20px;
`;

export const ShiftLi = styled.li`
  margin-top: 15px;
  color: ${Color.SeaBlue600};

  p {
    margin: 3px 0;
  }
`;

export const ImageWrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${Color.SeaBlue300};
  margin: 20px 10px 20px 0;
`;

export const WorkerWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  button {
    display: unset;
    margin: 0;
    margin-top: auto;
    align-self: flex-start;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SkillsWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
  margin: 15px 0;
  padding: 0 15px;
`;

export const SkillTag = styled.div`
  background-color: ${Color.SeaBlue600};
  padding: 5px;
  border-radius: 4px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LabelWrapperDescription = styled(LabelWrapper)`
  top: 0;
  position: sticky;
  padding-top: 10px;
  background: ${Color.SeaBlue200};
`;

export const Cell = styled.div`
  padding: 10px;
  background-color: ${Color.SeaBlue200};
  border-radius: 10px;
  @media (max-width: 768px) {
    margin: 10px 0px;
  }
`;

export const WrapperRowCells = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
export const DescriptionCell = styled(Cell)`
  padding: 0 10px 10px 10px;
  grid-column: 2/3;
  grid-row: 2/4;
  max-height: 330px;
  overflow-y: scroll;
`;

export const ShiftCell = styled(Cell)`
  grid-column: 2/3;
  grid-row: 4/6;
  max-height: 330px;
  overflow-y: scroll;
`;

export const EmployerCell = styled(Cell)`
  grid-column: 1/2;
  grid-row: 1/2;
  background: ${Color.Positive};
`;

export const SkillsCell = styled(Cell)`
  grid-column: 1/2;
`;

export const RateCell = styled(Cell)`
  grid-column: 1/2;
`;

export const BottomText = styled(Text)`
  margin: 0px;
  font-family: ${FontFamily.MontserratRegular}, Helvetica;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const OfferModalBody = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: ${Color.White};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  outline: none;
  border-radius: 8px;
  box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.2);

  .MuiInputBase-root {
    margin-bottom: 15px;
  }
  button {
    font-size: ${FontSize.Small};
  }

  p {
    margin: 0 0 30px 0;
  }
`;

export const OfferWorkerCell = styled.div<OfferCellProps>`
  display: flex;
  background-color: ${Color.White};
  padding: 10px;
  align-content: center;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 8px;
  justify-content: space-between;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  border-bottom: 1px solid ${Color.SeaBlue200};

  p {
    margin: 0;
  }
  &:hover {
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
    opacity: ${(props) => (props.disabled ? "0.5" : "0.7")};
  }
`;

export const WorkersContainer = styled.div`
  max-height: 300px;
  padding: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #cfcfcf #f9f9f9;
  width: 100%;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b3b3b3;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f9f9f9;
  }

`;
export const RatingRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px 0 0 0;
  p {
    margin: 0 0 0;
  }
`;