import { UserEndorsement } from "./Endorsement";
import { GigType, PaymentMethod } from "./Gig";
import { UserSkillDto } from "./User";

export enum TimeReportStatus {
  UNDEFINED = "Undefined",
  NEW = "New",
  SUBMITTED = "Submitted",
  APPROVED = "Approved",
  REJECTED = "Rejected",
  PROCESSING_PAYMENT = "ProcessingPayment",
  INVOICE_NOT_PAID = "InvoiceNotPaid",
  PAYMENT_STARTED = "PaymentStarted",
  PAID = "Paid",
  CLOSED = "Closed",
  CLOSED_BY_COMPANY = "ClosedByCompany",
}

export type SkillAndEndorsements = {
  endorsements: UserEndorsement[];
  skills: UserSkillDto[];
};

export type TimeReportDto = {
  id: number;
  workerId: number;
  gigId: number;
  gigShiftId: number;
  startTime: string;
  endTime: string;
  status: TimeReportStatus;
  userNotes: string | null;
  companyNotes: string | null;
  companyId: number;
  hourlyRate: number;
  resolution: TimeReportResolution;
  gigRole: string;
  gigType: GigType;
  paymentType?: PaymentMethod;
  firstName: string;
  lastName: string;
  isLastTimeReport?: boolean;
  breakStartTime?: string;
  breakEndTime?: string;
  breakTime?: string;
  updatedAt: string;
  checkInTime: string | null;
  checkOutTime: string | null;
  totalTime?: string | null;
};

export type TimeTotal = {
  plannedTime: string;
  approvedTime: string;
  approvedOverTime: string;
  plannedOverTime: string;
  submittedTime: string;
  remainingTime: string;
  absenceTime: string;
  absenceDetails: {
    sickLeave: string;
    sickLeaveChild: string;
    vacation: string;
    leaveOfAbsence: string;
    parentalLeave: string;
    other: string;
  };
};

export type Costs = {
  simflexityFeeTotal: number;
  grossCost: number;
  overTimeCost: number;
  socialFeeCost: number;
  total: number;
};

export type CostTotal = {
  grossSalary: number;
  obAmount: number;
  socialFee: number;
  simflexityFee: number;
  totalCost: number;
};

export type TotalCostMonthly = {
  approved: Costs;
  planned: Costs;
};

export type PersonnelCarrierTimeStamps = {
  timeReportId: number;
  gigId: number;
  gigRole: string;
  startTime: string;
  endTime: string;
  checkInTime: string | number | Date;
  checkOutTime: string | number | Date;
  breakTimeMinutes: number;
  status: string;
  resolution: string;
};

export type TimeReportPricingDto = {
  amountWithoutFee?: number;
  currency?: string;
  vat?: number;
  cost?: number;
  fee?: number;
  payroll?: number;
  amount?: number;
  invoiced_amount?: number;
  tax?: number;
  pension?: any;
  healthInsurance?: any;
};

export type TimeReportPaymentDto = {
  timeReportId: number;
  companyId: number;
};
export enum TimeReportResolution {
  UNDEFINED = "Undefined",
  PAID = "Paid",
  TIME_REPORTED = "TimeReported",
  CANCELLED = "Cancelled",
  PAYONDEMAND = "PayOnDemand",
  WITHDRAWN = "Withdrawn",
  SICKLEAVE = "SickLeave",
  SICKLEAVECHILD = "SickLeaveChild",
  VACATION = "Vacation",
  PARENTALLEAVE = "ParentalLeave",
  LEAVEOFABSENCE = "LeaveOfAbsence",
  OVERTIMECOMPENSATION = "OvertimeCompensation",
  FUNERALLEAVE = "FuneralLeave",
  STUDYLEAVE = "StudyLeave",
  LEAVEUNIONASSIGNEMENTS = "LeaveUnionAssignments",
  MILITARYSERVICE = "MilitaryService",
  OTHER = "Other",
}

export type WorkerWithTimeReports = {
  firstName: string;
  lastName: string;
  id: number;
  timeReports: TimeReportDto[];
};

export type WorkerWithTimeReportsForPersonnelCarrier = {
  workerId: number;
  firstName: string;
  lastName: string;
  ssn: string;
  stamps: PersonnelCarrierTimeStamps[];
};
