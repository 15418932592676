import React, { useEffect, useState } from "react";
import { CustomLink, Text } from "components/Typography/text.styled";
import { CompanyFavoriteDto } from "model/Favorite";
import { useTranslation } from "react-i18next";
import {
  getWorkerImg,
  getWorkerVideo,
} from "services/firebase/firebase.service";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { useAlertStore } from "stores/alertStore/alertStore";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CustomIcon } from "components/icon/customIcon.component";
import { useHistory } from "react-router-dom";
import { Modal } from "@material-ui/core";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { LabelStyled } from "components/form/inputs/inputs.styled";

import { Api } from "services/api/api.service";
import { UserEndorsement } from "model/Endorsement";
import { useApplicationStore } from "web-apps/company/stores/applicationStore/applicationStore";
import { WorkerEndorementModal } from "../../applicantDetails/workerEndorsementModal";
import { UserSkillDto } from "model/User";
import { CompanyWorkerDto } from "model/Company";
import {
  ButtonContainer,
  Cell,
  Column,
  DividerLine,
  ImageContainer,
  ModalBody,
  ProfileDetailsContainer,
  ProfileDiv,
  ProfileGrid,
  SkillCell,
  SkillsCell,
  StatsContainer,
  VideoContainer,
  VideoPresentation,
  VideoWrapper,
  WorkerImage,
} from "../../profile/favorite/favoriteDetail.styled";
import { WorkerDocuments } from "../../workerDocument/workerDocument.component";

type Props = {
  worker: CompanyWorkerDto;
};

export const WorkerDetail: React.FC<Props> = ({ worker }) => {
  const { t } = useTranslation();
  const [, , alertDispatch] = useAlertStore();
  const [, applicationActions] = useApplicationStore();
  const [companyState, companyActions] = useCompanyStore();
  const [workerImage, setWorkerImage] = useState("");
  const [workerVideo, setWorkerVideo] = useState<string | null>(null);
  const [favorited, setFavorited] = useState<
    CompanyFavoriteDto | null | undefined
  >(null);
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const [workerEndorsements, setWorkerEndorsements] = useState<
    UserEndorsement[]
  >([]);

  const [skillAlreadyEndorsed, setSkillAlreadyEndorsed] =
    useState<boolean>(false);
  const [workerEndorsementModalOpen, setWorkerEndorsementModalOpen] =
    useState(false);
  const [createWorkerEndorsementSkill, setCreateWorkerEndorsementSkill] =
    useState<UserSkillDto | null>(null);

  const removeFromFavorites = () => {
    if (favorited && companyState.company) {
      companyActions
        .removeCompanyFavorite(companyState.company?.id, favorited.id)
        .then(() => {
          history.push("../favorites");
        })
        .catch(() => {
          alertDispatch({
            type: "SHOW_ALERT",
            payload: {
              icon: "alert", // TODO: ADD SAME ICON AS IN APP
              title: t("Alerts.OhNo"),
              message: t("General.ErrorOccurred"),
            },
          });
        });
    }
  };

  const addToFavorites = () => {
    if (companyState.company) {
      companyActions
        .addCompanyFavorite(companyState.company?.id, worker.id)
        .then()
        .catch((err: any) => {
          if (err.response.data === "Worker does not have a valid SSN") {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "alert",
                title: t("Alerts.OhNo"),
                message: t("ApplicationsCompany.WorkerDoesNotHaveAValidSSN"),
              },
            });
          }
        });
    }
  };

  const createEndorsement = (skill: string) => {
    const companyEndorsement = workerEndorsements.find(
      (e) =>
        e.companyId === companyState?.company?.id &&
        e.skill.toLowerCase() === skill.toLowerCase()
    );

    if (companyEndorsement) {
      alertDispatch({
        type: "SHOW_ALERT",
        payload: {
          icon: "alert",
          title: t("Alerts.OhNo"),
          message: t("Alerts.SomethingWentWrong"),
        },
      });
    } else {
      if (skill && worker.id && companyState?.company?.id) {
        applicationActions
          .createApplicationWorkerEndorsement(
            companyState?.company?.id,
            worker.id,
            skill
          )
          .then((res) => {
            if (res.data?.id) {
              setWorkerEndorsements([...workerEndorsements, res.data]);
              alertDispatch({
                type: "SHOW_ALERT",
                payload: {
                  icon: "checkmark",
                  title: t("ApplicationsCompany.Endorsed"),
                  message: t(
                    "ApplicationsCompany.CreateEndorsementSuccessfully"
                  ),
                },
              });
            } else {
              alertDispatch({
                type: "SHOW_ALERT",
                payload: {
                  icon: "alert",
                  title: t("Alerts.OhNo"),
                  message: t("Alerts.SomethingWentWrong"),
                },
              });
            }
            setWorkerEndorsementModalOpen(false);
          });
      }
    }
  };
  const deleteEndorsement = (skill: string) => {
    const companyEndorsement = workerEndorsements.find(
      (e) =>
        e.companyId === companyState?.company?.id &&
        e.skill.toLowerCase() === skill.toLowerCase()
    );

    if (companyEndorsement) {
      applicationActions
        .deleteApplicationWorkerEndorsement(
          companyEndorsement.companyId,
          companyEndorsement.workerId,
          companyEndorsement.id
        )
        .then((res) => {
          if (res.status === 200) {
            setWorkerEndorsements(
              workerEndorsements.filter((e) => e.id !== companyEndorsement.id)
            );
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "checkmark",
                title: t("General.Deleted"),
                message: t("ApplicationsCompany.DeleteEndorsementSuccessfully"),
              },
            });
          } else {
            alertDispatch({
              type: "SHOW_ALERT",
              payload: {
                icon: "alert",
                title: t("Alerts.OhNo"),
                message: t("Alerts.SomethingWentWrong"),
              },
            });
          }
          setWorkerEndorsementModalOpen(false);
        });
    } else {
      setWorkerEndorsementModalOpen(false);
    }
  };

  useEffect(() => {
    getWorkerImg(worker.workerFirebaseId).then((res) => {
      setWorkerImage(res);
    });

    getWorkerVideo(worker.workerFirebaseId)
      .then((res) => setWorkerVideo(res))
      .catch(() => {
        setWorkerVideo(null);
      });

    const matching = companyState.favorites.find((worker) => {
      return worker.workerId === worker.id;
    });
    if (matching) {
      setFavorited(matching);
    } else {
      setFavorited(null);
    }
  }, [worker, companyState.favorites]);

  useEffect(() => {
    if (companyState.company) {
      Api()
        .company.worker.getWorkerEndorsements(
          companyState.company?.id,
          worker.id
        )
        .then((res) => {
          setWorkerEndorsements(res.data);
        });
    }
  }, [worker, companyState.company]);

  useEffect(() => {
    getWorkerImg(worker.workerFirebaseId).then((res) => {
      setWorkerImage(res);
    });

    getWorkerVideo(worker.workerFirebaseId)
      .then((res) => setWorkerVideo(res))
      .catch(() => {
        setWorkerVideo(null);
      });

    const matching = companyState.favorites.find((favorite) => {
      return favorite.workerId === worker.id;
    });
    if (matching) {
      setFavorited(matching);
    } else {
      setFavorited(null);
    }
  }, [worker, companyState.favorites]);

  const renderModalBody = () => {
    return (
      <>
        <Text
          align="center"
          fontSize={FontSize.H4}
          fontFamily={FontFamily.MontserratSemiBold}
        >
          {t("CompanyFavorite.AreYouSureRemoveFavorite")}
        </Text>
        <ButtonContainer>
          <ButtonStyled
            backgroundColor={Color.Disabled}
            color={Color.SeaBlue500}
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {t("General.Abort")}
          </ButtonStyled>
          <ButtonStyled
            backgroundColor={Color.Destructive}
            onClick={() => removeFromFavorites()}
          >
            {t("CompanyFavorite.Remove")}
          </ButtonStyled>
        </ButtonContainer>
      </>
    );
  };

  return (
    <div>
      <CustomLink
        onClick={() => history.goBack()}
        color={Color.Destructive}
        text-align="right"
      >
        {t("General.Back")}
      </CustomLink>
      <ProfileGrid>
        <ProfileDiv>
          <ImageContainer>
            {workerImage ? (
              <WorkerImage src={workerImage} />
            ) : (
              <CustomIcon
                className="icon"
                name="person"
                size="190px"
                color={Color.SeaBlue500}
                backgroundColor={Color.SeaBlue300}
                padding="10px"
              />
            )}
            {favorited ? (
              <CustomIcon
                className="icon"
                name="favorites-active"
                size="48px"
                color={Color.White}
                backgroundColor={Color.BurntSienna}
                padding="10px"
                onClick={() => setModalOpen(true)}
              />
            ) : (
              <CustomIcon
                className="icon"
                name="favorites-inactive"
                size="48px"
                color={Color.White}
                backgroundColor={Color.BurntSienna}
                padding="10px"
                onClick={addToFavorites}
              />
            )}
          </ImageContainer>
          <Text
            fontSize={FontSize.H4}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {worker.firstName} {worker.lastName}
          </Text>
          <Text
            color={Color.SeaBlue600}
            fontSize={FontSize.Large}
            fontFamily={FontFamily.MontserratSemiBold}
          >
            {worker.city}
          </Text>
          <StatsContainer>
            {worker.stats &&
              worker.stats.some(
                (stat: any) => stat.stat === "AmountOfHoursWorked"
              ) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <CustomIcon
                      name="clock"
                      color={Color.BurntSienna}
                      size="24px"
                      style={{ marginRight: "4px" }}
                    />
                    <Text
                      color={Color.MidnightBlue}
                      fontFamily={FontFamily.MontserratBold}
                      fontSize={FontSize.Large}
                    >
                      {worker.stats &&
                      worker.stats.some(
                        (stat: any) => stat.stat === "AmountOfHoursWorked"
                      )
                        ? worker.stats
                            .find(
                              (stat: any) => stat.stat === "AmountOfHoursWorked"
                            )
                            .value.toFixed(0)
                        : 0}
                    </Text>
                  </div>
                  <Text color={Color.SeaBlue500}>
                    {t("ProfileWorker.HoursWorked")}
                  </Text>
                </div>
              )}

            {worker.stats &&
            worker.stats.some((stat: any) => stat.stat === "Rating") ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <CustomIcon
                    name="star"
                    color={Color.BurntSienna}
                    size="20px"
                    style={{ marginRight: "4px" }}
                  />
                  <Text
                    color={Color.MidnightBlue}
                    fontFamily={FontFamily.MontserratBold}
                    fontSize={FontSize.Large}
                  >
                    {worker.stats &&
                    worker.stats.some((stat: any) => stat.stat === "Rating")
                      ? worker.stats
                          .find((stat: any) => stat.stat === "Rating")
                          .value.toFixed(1)
                      : 0}
                    /5
                  </Text>
                  <Text
                    color={Color.SeaBlue500}
                    fontFamily={FontFamily.MontserratRegular}
                    fontSize={FontSize.Standard}
                    style={{ marginLeft: "4px" }}
                  >
                    {worker.stats &&
                    worker.stats.some(
                      (stat: any) => stat.stat === "AmountOfRatings"
                    )
                      ? "(" +
                        worker.stats
                          .find((stat: any) => stat.stat === "AmountOfRatings")
                          .value.toFixed(0) +
                        ")"
                      : "(" + 0 + ")"}
                  </Text>
                </div>
                <Text color={Color.SeaBlue500}>
                  {t("ProfileWorker.Rating")}
                </Text>
              </div>
            ) : (
              <Text color={Color.SeaBlue500}>
                {t("ProfileWorker.NoRatingsYet")}
              </Text>
            )}
          </StatsContainer>
          <ProfileDetailsContainer>
            <LabelStyled>{t("ProfileWorker.Contact")}</LabelStyled>
            <Text
              style={{ wordBreak: "break-all" }}
              fontFamily={FontFamily.MontserratSemiBold}
            >
              <a
                href={worker.email && "mailto:" + worker.email}
                style={{ color: Color.BurntSienna }}
              >
                {worker.email || ""}
              </a>
            </Text>

            <Text
              style={{ wordBreak: "break-all" }}
              fontFamily={FontFamily.MontserratSemiBold}
            >
              <a
                style={{ color: Color.BurntSienna }}
                href={`tel:+${worker.phoneNumber}`}
              >
                {`+${worker.phoneNumber}`}
              </a>
            </Text>
          </ProfileDetailsContainer>
          <VideoContainer>
            <LabelStyled>{t("ProfileWorker.VideoPresentation")}</LabelStyled>
            {workerVideo ? (
              <VideoWrapper>
                <VideoPresentation controls src={workerVideo} />
              </VideoWrapper>
            ) : (
              <Text color={Color.SeaBlue400}>
                {t("ProfileWorker.NoVideoPresentation")}
              </Text>
            )}
          </VideoContainer>
          {worker.workerSkills?.length !== 0 && (
            <ProfileDetailsContainer>
              <LabelStyled>{t("ProfileWorker.Skills")}</LabelStyled>
              <SkillsCell>
                {worker.workerSkills?.map((skill, index) => (
                  <SkillCell
                    onClick={() => {
                      workerEndorsements.find(
                        (endorsement) =>
                          endorsement.companyId === companyState.company?.id &&
                          endorsement.skill.toLowerCase() ===
                            skill.skill.toLowerCase()
                      )
                        ? setSkillAlreadyEndorsed(true)
                        : setSkillAlreadyEndorsed(false);
                      setWorkerEndorsementModalOpen(true);
                      setCreateWorkerEndorsementSkill(skill);
                    }}
                  >
                    <Column>
                      <Text
                        fontSize={FontSize.Large}
                        color={Color.MidnightBlue}
                        fontFamily={FontFamily.MontserratSemiBold}
                        style={{ marginBottom: "4px" }}
                      >
                        {t(skill.skill, { ns: "skills" })}
                      </Text>
                      <Text fontSize={FontSize.Small} color={Color.SeaBlue500}>
                        {skill.experience === 0
                          ? `3-9 ${t("ProfileWorker.Months")}`
                          : skill.experience < 2
                          ? `1-2 ${t("ProfileWorker.Years")}`
                          : skill.experience < 5
                          ? `2-5 ${t("ProfileWorker.Years")}`
                          : `+5 ${t("ProfileWorker.Years")}`}
                      </Text>
                    </Column>
                    <Text
                      fontSize={FontSize.Small}
                      color={Color.SeaBlue500}
                      fontFamily={FontFamily.MontserratRegular}
                    >
                      {
                        workerEndorsements.filter(
                          (endorsement) =>
                            endorsement.skill.toLowerCase() ===
                            skill.skill.toLowerCase()
                        ).length
                      }{" "}
                      {t("ProfileWorker.Endorsements")}
                    </Text>
                  </SkillCell>
                ))}
              </SkillsCell>
            </ProfileDetailsContainer>
          )}
          <WorkerDocuments documents={worker.documents} />
        </ProfileDiv>
        <DividerLine />
        <Cell>
          <LabelStyled>{t("ProfileWorker.Bio")}</LabelStyled>
          <Text
            fontSize={FontSize.Large}
            style={{ wordBreak: "break-all", marginBottom: "16px" }}
          >
            {worker.description}
          </Text>
          <LabelStyled style={{ marginTop: "18px" }}>
            {t("CompanyGroup.AssignedHourlyRate")}
          </LabelStyled>
          {worker.hourlyRate ? (
            <Text
              fontSize={FontSize.Large}
              style={{ wordBreak: "break-all", marginBottom: "12px" }}
            >
              {`${worker.hourlyRate} ${t("FindWorker.HourlyRateWithCurrency")}`}
            </Text>
          ) : (
            <Text
              fontSize={FontSize.Large}
              color={Color.SeaBlue500}
              style={{ wordBreak: "break-all", marginBottom: "12px" }}
            >
              {t("CompanyGroup.NoDefaultRate")}
            </Text>
          )}
        </Cell>
      </ProfileGrid>
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalBody>{renderModalBody()}</ModalBody>
      </Modal>
      {createWorkerEndorsementSkill && (
        <WorkerEndorementModal
          workerEndorsementModalOpen={workerEndorsementModalOpen}
          createWorkerEndorsementSkill={createWorkerEndorsementSkill}
          deleteEndorsement={deleteEndorsement}
          createEndorsement={createEndorsement}
          setWorkerEndorsementModalOpen={setWorkerEndorsementModalOpen}
          skillAlreadyEndorsed={skillAlreadyEndorsed}
          workerEndorsements={workerEndorsements}
        />
      )}
    </div>
  );
};
