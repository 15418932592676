import React, { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { H3 } from "../../../../../components/Typography/text.styled";
import {
  LoggedInPageContainer,
  LoggedOutPageContainer,
} from "../../../../../components/pageLayouts/pageLayouts.styled";
import { useCompanyStore } from "../../../stores/companyStore/companyStore";
import { useAuthStore } from "../../../../../stores/authStore/authStore";
import { RouteComponentProps } from "react-router-dom";
import { CompanyWorkerDto } from "model/Company";
import { WorkerDetail } from "web-apps/company/components/timeReport/workers/workerDetail.component";

type MatchProps = {
  workerId?: string;
};

export const WorkerDetailPage: React.FC<RouteComponentProps<MatchProps>> = (
  props
) => {
  const [authState] = useAuthStore();
  const workerId = props.match.params.workerId;
  const [companyState, companyActions] = useCompanyStore();
  const [worker, setWorker] = useState<CompanyWorkerDto>();

  useEffect(() => {
    if (workerId) {
      if (companyState.company?.id) {
        companyActions
          .getWorkerDetailsByWorkerId(
            companyState.company?.id,
            Number(workerId)
          )
          .then((res) => {
            setWorker(res);
          })
          .catch((err: any) => {
            console.log(err);
          });
      }
    }
  }, [companyState.company?.id, workerId]);

  return (
    <>
      {authState.isLoading || companyState.isLoading ? (
        <LoggedOutPageContainer>
          <CircularProgress />
        </LoggedOutPageContainer>
      ) : (
        <LoggedInPageContainer>
          {worker && (
            <>
              <WorkerDetail worker={worker} />
            </>
          )}
        </LoggedInPageContainer>
      )}
    </>
  );
};
